import { Tooltip } from '@material-ui/core';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ScrollText from 'react-scroll-text';
import { usePodcastContext } from '@hooks/podcast';
import { ITrack } from '@models/player.interface';
import { VolumeUp } from '@material-ui/icons';

import { getEpisodeCache, setEpisodeCache } from '@services/podcastCache';
import { getPodcastAndEpisodeBySlug } from 'services/content/podcast';

import { useTracking } from '@hooks/tracking';
import Controls from './Controls';
import {
  AudioAuthor,
  AudioInfoContainer,
  AudioTitle,
  BackgroundImg,
  Container,
  ControlsContainer,
  Img,
  SliderComponent,
  TimeSliderContainer,
  TimeSliderText,
  RightContainer,
  VolumeContainer,
  CloseButton,
  ClosePlayerIcon,
  AudioInfoText,
  AudioInfoTextMobile,
} from './styles';

const PodcastPlayer: React.FC = () => {
  const { track } = useTracking();
  const {
    trackIndex,
    setTrackIndex,
    trackQueue,
    setTrackQueue,
    isPlaying,
    setIsPlaying,
    showPlayer,
    setShowPlayer,
    currentTime,
    setCurrentTime,
    onPrev,
    onClose,
    blockPlay,
  } = usePodcastContext();
  const UPDATE_CACHE_RATE = 30;

  const [currentTrack, setCurrentTrack] = useState<ITrack>(undefined);
  const [volume, setVolume] = useState(0.65);
  const [lastCurrentTime, setLastCurrentTime] = useState(0);

  const audioRef = useRef<HTMLAudioElement | undefined>(
    typeof Audio !== 'undefined'
      ? new Audio(currentTrack?.audioUrl)
      : undefined,
  );
  const intervalRef = useRef(null);

  const duration = useMemo(() => {
    if (
      currentTrack &&
      audioRef &&
      audioRef.current &&
      audioRef.current.duration
    )
      return audioRef.current.duration;

    return 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTrack, audioRef, audioRef?.current, audioRef?.current?.duration]);

  const getCachedTime = trackId => {
    return getEpisodeCache(trackId).currentTime;
  };

  const toPrevTrack = useCallback(() => {
    if (onPrev) {
      onPrev();
      return;
    }

    sendListenedTrack();
    if (trackIndex > 0) {
      setTrackIndex(trackIndex - 1);
    } else {
      setTrackIndex(0);
      onScrub(null, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onPrev, setTrackIndex, trackIndex]);

  const toNextTrack = useCallback(async () => {
    if (trackQueue.length === trackIndex + 1) {
      const getPodcastAndEpisode = await getPodcastAndEpisodeBySlug(
        trackQueue[0].authorSlug,
        trackIndex + 1,
        trackIndex,
      );

      if (getPodcastAndEpisode.episodes.length > 0) {
        const trackQueueState = {
          authorSlug: getPodcastAndEpisode.slug,
          id: getPodcastAndEpisode.episodes[0].id,
          trackSlug: getPodcastAndEpisode.episodes[0].slug,
          audioUrl: getPodcastAndEpisode.episodes[0].audioUrl,
          title: getPodcastAndEpisode.episodes[0].title,
          imageUrl: getPodcastAndEpisode.episodes[0].image.url,
          author: getPodcastAndEpisode.title,
          duration: getPodcastAndEpisode.episodes[0].duration,
          category: getPodcastAndEpisode.category?.name,
        };
        setTrackQueue([...trackQueue, trackQueueState]);
        setTrackIndex(trackIndex + 1);
      } else {
        setTrackIndex(0);
      }
      sendListenedTrack();
    }

    if (trackIndex < trackQueue?.length - 1) {
      setTrackIndex(trackIndex + 1);
      sendListenedTrack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTrackIndex, trackIndex, trackQueue?.length]);

  const onScrub = (e, value) => {
    setLastCurrentTime(value);
    audioRef.current.currentTime = value;
    setCurrentTime(audioRef.current.currentTime);
  };

  const onScrubEnd = () => {
    if (!isPlaying) {
      setIsPlaying(true);
    }
    startTimer();
  };

  const handleVolumeChange = (event, value) => {
    setVolume(value);
    audioRef.current.volume = value;
  };

  const sendListenedTrack = useCallback(() => {
    track('Listened track', {
      podcastSlug: currentTrack?.authorSlug,
      episodeSlug: currentTrack?.trackSlug,
      time: currentTime,
    });
  }, [currentTrack, track, currentTime]);

  const startTimer = useCallback(() => {
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (!audioRef) return;

      if (audioRef.current.ended) {
        toNextTrack();
        track('Listened to the end', {
          podcastSlug: currentTrack.authorSlug,
          episodeSlug: currentTrack.trackSlug,
        });
      } else {
        setCurrentTime(audioRef.current.currentTime);
      }
    }, 1000);
  }, [setCurrentTime, toNextTrack, audioRef, track, currentTrack]);

  const secondsToTime = seconds => {
    if (!seconds) return '--:--';

    const hours = Math.floor(seconds / 3600);
    const min = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
    const sec = String(Math.floor(seconds % 60)).padStart(2, '0');

    return hours > 0 ? `${hours}:${min}:${sec}` : `${min}:${sec}`;
  };

  useEffect(() => {
    if (isPlaying && !showPlayer) {
      setShowPlayer(true);
    }

    if (isPlaying && !blockPlay) {
      audioRef.current.play();
      startTimer();
    } else {
      clearInterval(intervalRef.current);
      audioRef.current.pause();
    }
  }, [
    audioRef,
    isPlaying,
    setIsPlaying,
    setShowPlayer,
    showPlayer,
    startTimer,
    blockPlay,
  ]);

  useEffect(() => {
    if (trackQueue && trackIndex >= 0) {
      setCurrentTrack(trackQueue[trackIndex]);
    }
  }, [trackIndex, trackQueue]);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    const onUnload = () => {
      if (showPlayer) sendListenedTrack();
    };
    window.addEventListener('unload', onUnload);

    return () => window.removeEventListener('unload', onUnload);
  }, [showPlayer, sendListenedTrack]);

  useEffect(() => {
    if (!currentTrack) return;
    audioRef.current.pause();
    audioRef.current = new Audio(currentTrack.audioUrl);
    audioRef.current.currentTime = getCachedTime(currentTrack?.id);
    setLastCurrentTime(audioRef.current.currentTime);
    setCurrentTime(audioRef.current.currentTime);
    if (!blockPlay) {
      audioRef.current.play();
      startTimer();
      setIsPlaying(true);
    }
  }, [
    setCurrentTime,
    setIsPlaying,
    startTimer,
    track,
    audioRef,
    blockPlay,
    currentTrack,
  ]);

  useEffect(() => {
    if (currentTime > lastCurrentTime + UPDATE_CACHE_RATE) {
      setLastCurrentTime(currentTime);
      setEpisodeCache(currentTrack.id, { currentTime });
    }
  }, [currentTime, lastCurrentTime, currentTrack]);

  const titleWithScroll = useMemo(() => {
    return (
      <ScrollText speed={20} style={{ position: 'relative', top: 5 }}>
        <AudioTitle>{currentTrack?.title}</AudioTitle>
      </ScrollText>
    );
  }, [currentTrack?.title]);

  return (
    <Container showPlayer={showPlayer}>
      <AudioInfoContainer>
        <BackgroundImg>
          <Img
            src={currentTrack?.imageUrl || '/share.webp'}
            layout="fill"
            objectFit="cover"
            quality="50"
          />
        </BackgroundImg>

        <AudioInfoText>
          <Tooltip title={currentTrack?.title}>
            <AudioTitle>{currentTrack?.title}</AudioTitle>
          </Tooltip>
          <AudioAuthor>{currentTrack?.author}</AudioAuthor>
        </AudioInfoText>
        <AudioInfoTextMobile>
          {titleWithScroll}
          <AudioAuthor>{currentTrack?.author}</AudioAuthor>
        </AudioInfoTextMobile>
      </AudioInfoContainer>

      <ControlsContainer>
        <Controls
          isPlaying={isPlaying}
          onPrevClick={toPrevTrack}
          onNextClick={toNextTrack}
          onPlayPauseClick={setIsPlaying}
        />
        <TimeSliderContainer>
          <TimeSliderText>{secondsToTime(currentTime)}</TimeSliderText>
          <SliderComponent
            min={0}
            max={Number(duration)}
            value={currentTime}
            step={1}
            onChange={onScrub}
            onChangeCommitted={onScrubEnd}
          />
          {duration && (
            <TimeSliderText>{secondsToTime(duration)}</TimeSliderText>
          )}
        </TimeSliderContainer>
      </ControlsContainer>

      <RightContainer>
        <VolumeContainer>
          <VolumeUp style={{ marginBottom: 2 }} />
          <SliderComponent
            min={0}
            max={1}
            value={volume}
            step={0.05}
            onChange={handleVolumeChange}
          />
        </VolumeContainer>
      </RightContainer>
      <CloseButton
        onClick={() => {
          if (onClose) {
            onClose();
            return;
          }
          if (isPlaying) {
            setIsPlaying(false);
          }
          setShowPlayer(false);

          track('Close player', {
            podcastSlug: currentTrack?.authorSlug,
            episodeSlug: currentTrack?.trackSlug,
          });

          sendListenedTrack();
        }}
      >
        <ClosePlayerIcon />
      </CloseButton>
    </Container>
  );
};

export default PodcastPlayer;
