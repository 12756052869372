import { PodcastsEpisodesReleases, Podcast } from '@models/podcast.interface';
import api from '../api';

export const getPodcastLastEpisodes = async (): Promise<
  PodcastsEpisodesReleases[]
> => {
  const response = await api.get(
    `/podcasts/episodes/releases?limit=5&offset=0`,
  );
  return response.data?.episodes;
};

export const getPodcastAndEpisodeBySlug = async (
  slug: string,
  limit: number,
  offset: number,
): Promise<Podcast> => {
  const params = new URLSearchParams();
  params.append('limit', String(limit));
  params.append('offset', String(offset));
  const response = await api.get(`/podcasts/${slug}`, { params });
  return response.data;
};

export const getFeaturedEpisodes = async (): Promise<any> => {
  const response = await api.get(`/podcasts/episodes/featured`);
  return response.data;
};

export const getOurPrograms = async (
  limit: number,
  offset: number,
): Promise<any> => {
  const response = await api.get(
    `/podcasts/programs?limit=${limit}&offset=${offset}`,
  );
  return response.data;
};
